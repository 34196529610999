import React from 'react'
import { Content, UnorderedList, Line } from './SparePartsElements';


function SpareParts() {
    return (
        <>
            <Content>
                <h1>Finding the parts you need</h1>
                <ol>
                    <li>Please select the pdf that is for your module of cooler.</li>
                    <li>On the first page of the documents you will see a numbered diagram. Each number represents a part of the cooler. This is to help you identify the part and makes it easier for you to communicate with us.</li>
                    <li>When contacting us via email or the form please make sure to send us the part number somewhere in your message.</li>
                </ol>

                <UnorderedList>
                    <Line><a href="../Documents/TBQ Spare Parts Price List.pdf" download>TBQ Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/ICON Spare Parts Price List.pdf" download>ICON Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/TBQI550 Spare Parts Price List.pdf" download>TBQI550 Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/CPQ Spare Parts Price List.pdf" download>CPQ Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/CVQ Spare Parts Price List.pdf" download>CVQ Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/EA DOWN Spare Parts Price List.pdf" download>EA DOWN Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/EA SIDE Spare Parts Price List.pdf" download>EA SIDE Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/EA TOP Spare Parts Price List.pdf" download>EA TOP Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/EM, EX Spare Parts Price List.pdf" download>EM, EX Spare Parts Price List</a></Line>
                    <Line><a href="../Documents/Scroll & Fan Spare Parts Price List.pdf" download>Scroll & Fan Spare Parts Price List</a></Line>
                </UnorderedList>

            </Content>
        </>
    )
}

export default SpareParts
