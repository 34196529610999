import styled from "styled-components";

export const Container = styled.div`
    margin: 5vh 20vw; 
    text-align: left;
`

export const ImageContainer = styled.div`
    display: flex; 
    justify-content: space-around;

    @media screen and (max-width:750px){
        flex-wrap: wrap;
    }
`

export const Image = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`