import styled from "styled-components";

export const MainFooter = styled.div`
    background: #074D67; 
    color: #42AEB8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:990px){
        flex-direction: column
    }
`

export const ContactInfo = styled.div`
    padding-left: 2vw;
    margin: 0;
    font-size: 1.8rem;
    word-wrap: normal;
    width: 38vw
    overflow-wrap: break-word;
`
export const P = styled.p`

    padding: 0;
`

export const S = styled.span`
    color:#5762d5;
`

