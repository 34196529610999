import { MainFooter, ContactInfo, P, S } from "./FooterElements";
import { Maps } from "../Map/map";

const Footer = () => {
    return (
        <MainFooter>
            <ContactInfo>
                <h1>Contact info</h1>
                <P><S>Email:</S>Shakira Venter - accounts@qmagic.co.za</P>
                <P><S>Contact No:</S> 011-609-7861</P>
                <P><S>Address:</S> 14 Pressburg Rd, Founders Hill Lethabong, 1609</P>
            </ContactInfo>
            <Maps />
        </MainFooter>
    );
}
 
export default Footer;