import styled from "styled-components";

export const Content = styled.div`
    margin: 2rem 8rem 2rem 8rem; 
    text-align: center;
    min-height: 80vh;
`

export const ImageContainer = styled.div`
    display: flex; 
    justify-content: space-around;
    flex-wrap: wrap;
`

export const Image = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1vh;
    font-size: clamp(1rem, 10vw, 2rem);
    color: #5762d5;
`