import React from 'react';
import '../../index.css';
import './hero.css';
import { Carousel } from 'react-carousel-minimal';





function Hero() {
  const arr = [
    {
        id: 1,
        image: "../../Images/Slider/1605511648461.jpeg"
    },
    {
        id: 2,
        image: "../../Images/Slider/1620308216521.jpg"
    },
    {
        id: 3,
        image: "../../Images/Slider/1624040029590.jpeg"
    },
    {
        id: 4,
        image: "../../Images/Slider/1630525331686.jpeg"
    },
    {
        id: 5,
        image: "../../Images/Slider/tester.jpeg"
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
          <Carousel
            data={arr}
            time={20000}
            width="90vw"
            height="80vh"
            captionStyle={captionStyle}
            radius="0px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            //slideBackgroundColor="darkgrey"
            slideImageFit="fill"
            thumbnails={false}
            thumbnailWidth="0px"
            style={{
              textAlign: "center",
              maxWidth: "90vw",
              maxHeight: "87vh",
              margin: "40px auto",
              overflow: "hidden"
            }}
          />
      </div>
    </div>
  );
}


export default Hero;
