import React from 'react';
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarWapper, 
    SidebarMenu, 
    SidebarLink,
    SidebarBtnWrap, 
    SidebarRoute } from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>        
            <SidebarWapper>
                <SidebarMenu>
                    <SidebarLink to='/evaporative-coolers'>Evaporative Coolers</SidebarLink>
                    <SidebarLink to='/spare-parts'>Spare parts</SidebarLink>
                    <SidebarLink to='/brochures'>Brochures</SidebarLink>
                    <SidebarLink to='/pricing'>Pricing</SidebarLink>
                </SidebarMenu>
                <SidebarBtnWrap>
                    <SidebarRoute to='/contact-us'>Contact Us</SidebarRoute>
                </SidebarBtnWrap>
            </SidebarWapper>
        </SidebarContainer>
        
    );
}
 
export default Sidebar;