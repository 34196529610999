import React, { Component } from 'react';
import { Form, Label, Input, Button } from 'reactstrap';
import './index.css';
import Recaptcha from 'react-recaptcha';

class Emailcomponent extends Component{
    constructor(){
        super()

        this.state = {
            name: '',
            companyName: '',
            email: '',
            contactNumber: '',
            message: '',
            isVerified: false,     
        }
        this.handleChange = this.handleChange.bind(this);
        this.onloadCallback = this.onloadCallback.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    
    async handleSubmit(e){
        e.preventDefault();
        if (this.state.isVerified){
            await window.Email.send({
                Host: process.env.REACT_APP_HOST,
                Username: process.env.REACT_APP_USERNAME,
                Password: process.env.REACT_APP_PASSWORD,
                To: "accounts@qmagic.co.za",
                From: "website@coolstar.co.za",
                Subject: "Message from Coolstar website",
                Body: "Name: " + this.state.name + "<br> Company: " + this.state.companyName + "<br> Email: " + this.state.email + "<br> Contact Number: " + this.state.contactNumber + "<br> Message: " + this.state.message
            }).then(msg =>alert("Thank you for contacting us"));
            window.location="./";
        }   
        else{
            alert('Please complete Recaptcha');
        }
    }
        
    
    onloadCallback(){
        console.log('recaptcha loaded');
    }

    verifyCallback = response =>{
        if(response){
            this.setState({
                isVerified: true
            })
            console.log("Final step:")
            console.log(this.state.isVerified);
        }
    }


    render(){
        return (
            <Form>
            <Label size='lg'>Contact Us</Label>
                <Input
                    id='edtName'
                    name='name'
                    placeholder='Name and Surname'
                    type='text'
                    required='true'
                    onChange={this.handleChange}
                />
                <Input
                    id='edtCompany'
                    name='companyName'
                    placeholder='Company Name'
                    type='text'
                    required='true'
                    onChange={this.handleChange}
                />
                <Input
                    id='edtEmail'
                    name='email'
                    placeholder='Email'
                    type='email'
                    required='true'
                    onChange={this.handleChange}
                />
                <Input
                    id='edtNumber'
                    name='contactNumber'
                    placeholder='Contact Number'
                    type='number'
                    required='true'
                    onChange={this.handleChange}
                />
                <Input
                    id='edtMessage'
                    name='message'
                    placeholder='Enter message here'
                    type='textarea'
                    required='true'
                    onChange={this.handleChange}
                />

                <Recaptcha 
                sitekey='6LcibDceAAAAAJIy8X1ImhmuduRTtMFtgn9IVUK_'
                render='explicit'
                onloadCallback={this.onloadCallback}
                verifyCallback={this.verifyCallback}
                />   

                <Button onClick={this.handleSubmit}>
                    Submit
                </Button>
            </Form>
        );
    }

}
   

export default Emailcomponent;


// REACT_APP_HOST = "156.38.224.14"

// REACT_APP_USERNAME = "website@coolstar.co.za"

// REACT_APP_PASSWORD = "Ej5lt8#3"