import styled from "styled-components";

export const Content = styled.div`
    margin: 5vh 20vw; 
    text-align: left;
    height: 80vh;
`

export const UnorderedList = styled.ul`
    margin-left: 15vw
`

export const Line = styled.li`
    padding-bottom: 10px;
`