import React, {useState} from 'react'
import Sidebar from '../Menu/Sidebar'
import Navbar from '../Menu/Navbar'

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} /> 
            <Navbar toggle={toggle} />
        </>
    )
}

export default Nav
