import './App.css';
import './index.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Components
import Nav from './Components/Menu';
import Footer from '../src/Components/Footer/footer';

//Pages
import Hero from '../src/Components/HeroSection';
import EC from '../src/Pages/Evaporative Coolers';
import SP from '../src/Pages/Spare Parts';
import B from '../src/Pages/Brochures';
import P from '../src/Pages/Pricing';
import ContactUs from '../src/Pages/Contact Us';



function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route exact path="/" element={<Hero />} />
          
        <Route path="/evaporative-coolers" element={<EC />} />
          
        <Route path="/spare-parts" element={<SP />} />
          
        <Route path="/brochures" element={<B />} />
          
        <Route path="/pricing" element={<P />} />
          
        <Route path="/contact-us" element={<ContactUs />} />
          
      </Routes>
      <Footer />
    </Router>

  );
}

export default App;
