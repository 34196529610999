import React, { Component } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// import Iframe from 'react-iframe';

// const LatLong= {lat: -26.102436611055438, lng: 28.174128794846155}
// const containerStyle = {
//   width: '100%',
//   height: '300px',
//   margin: '2rem',
// };


export class Maps extends Component {
  render() {
    return (
    //   <LoadScript
    //     googleMapsApiKey="AIzaSyBUthCFRI-htKRicYjHJ9aEpaeiZcbS4Vk"
    //   >
    //     <GoogleMap
    //       mapContainerStyle={containerStyle}
    //       center= {LatLong}
    //       zoom={19}
    //     >
    //     <Marker
    //         position={LatLong}
    //     />
    //     <></>
    //     </GoogleMap>
    //   </LoadScript>
    <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d316.68519376987183!2d28.173900765148474!3d-26.102708493420288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95133c32c1b451%3A0x5bf20c691a78e803!2sQ%20Display!5e0!3m2!1sen!2sza!4v1644312784958!5m2!1sen!2sza" width="90%" height="250px"  allowfullscreen="" loading="lazy" style={{margin:'2vh 2vw'}}></iframe>
     )
    
  }
}