import React from 'react'
import { Content } from './BrochuresElements'

function Brochures() {
    return (
        <>
            <Content>
                <h1>Brochures</h1>
                <p>If you would like more information on any of our products; Please select a brochure you would like to download below.</p>
                <ul>
                    <li><a href="../Documents/Brochure/1 Braemar_DGH Flyer_SAF v1.pdf">Braemar_DGH Flyer_SAF v1</a></li>
                    <li><a href="../Documents/Brochure/1 ClimateWizard_Brochure_AUS_ NEW.pdf">ClimateWizard_Brochure</a></li>
                    <li><a href="../Documents/Brochure/2 Braemar_SA_Comparison_.pdf">Braemar_SA_Comparison</a></li>
                    <li><a href="../Documents/Brochure/2017 RPB Brochure - Print.pdf">2017 RPB Brochure</a></li>
                    <li><a href="../Documents/Brochure/3 Climate Wizard Application Guide.pdf">Climate Wizard Application Guide</a></li>
                    <li><a href="../Documents/Brochure/5 ClimateWizard_Data center brochure.pdf">ClimateWizard_Data center</a></li>
                    <li><a href="../Documents/Brochure/Braemar_RPB_Range.pdf">Braemar_RPB_Range</a></li>
                    <li><a href="../Documents/Brochure/Breezair_Evolution brochure_Dealer_REV_A_0518_PRINTv3.pdf">Breezair_Evolution</a></li>
                    <li><a href="../Documents/Brochure/Breezair_SAF_IconEXQ130, 170, 210_REVA_1016_flyer.pdf">Breezair_SAF_IconEXQ130, 170, 210</a></li>
                    <li><a href="../Documents/Brochure/Breezair_SAF_TBQ550_1015_flyer_F.pdf">Breezair_SAF_TBQ550_1015</a></li>
                    <li><a href="../Documents/Brochure/Breezair_SAF_TBQI550_SuperStealth_1016_flyer_F.pdf">Breezair_SAF_TBQI550_SuperStealth_1016</a></li>
                    <li><a href="../Documents/Brochure/brochure Climate wizard.pdf">Climate wizard</a></li>
                    <li><a href="../Documents/Brochure/C0108_2pp_REV_B_1220-CVQ1100.pdf">C0108_2pp_REV_B_1220-CVQ1100</a></li>
                    <li><a href="../Documents/Brochure/CW_CW3_Res_8pp_CW076_1019_RevC.pdf">CW_CW3_Res_8pp_CW076_1019_RevC</a></li>
                    <li><a href="../Documents/Brochure/RPB1800 D&T Specs.pdf">RPB1800 D&T Specs</a></li>
                    <li><a href="../Documents/Brochure/SI DGH Tech April 17.pdf">SI DGH Tech April 17</a></li>
                </ul>
            </Content>
        </>
    )
}

export default Brochures
