import React from 'react';
import '../../../index.css';
import { FaBars } from 'react-icons/fa';
import {
    Nav, 
    NavBarContainer, 
    NavLogo, 
    MoblieIcon, 
    NavMenu, 
    NavItems, 
    NavLink, 
    NavBtn,
    NavBtnLink} from './NavbarElements';

const Navbar = ({ toggle }) => {
    return (
        <>
            <Nav>
                <NavBarContainer>
                    <NavLogo to='/'>
                    COOLST★R™
                    </NavLogo>
                    <MoblieIcon onClick={toggle}>
                        <FaBars />
                    </MoblieIcon>
                    <NavMenu>
                        <NavItems>
                            <NavLink to="/evaporative-coolers">Evaporative Coolers</NavLink>
                        </NavItems>
                        <NavItems>
                            <NavLink to="/spare-parts">Spare parts</NavLink>
                        </NavItems>
                        <NavItems>
                            <NavLink to="/brochures">Brochures</NavLink>
                        </NavItems>
                        <NavItems>
                            <NavLink to="/pricing">Pricing</NavLink>
                        </NavItems>
                        {/* <NavItems>
                            <NavLink to="/about">About</NavLink>
                        </NavItems> */}
                        {/*<NavItems>
                            <NavLink to="/capital-management">Capital Management</NavLink>
                        </NavItems> */}
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="/contact-us">Contact Us</NavBtnLink>    
                    </NavBtn>
                </NavBarContainer>
            </Nav>
        </>
    );
}
 
export default Navbar;