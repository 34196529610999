import React from 'react'
import { Container, ImageContainer, Image } from './EvaporativeCoolersElements'

function EvaporativeCoolers() {
    return (
        <>
            <Container>
                <h1>WHAT IS EVAPORATIVE COOLING</h1>
                <p>Evaporative cooling uses evaporation to help cool the air. Based on the principles of evaporation, hot and dry outside air is drawn through water-soaked cooling pads. As the air is pushed through these pads, the water evaporates and the heat in the air is absorbed, which lowers the air temperature. A fan then pushes the cool air throughout the house via a network of ducts.</p>
                <p>The key to effective evaporative cooling is ensuring that each of the cooling pads are completely saturated during operation and that the systems fan and motor are sized and designed to deliver the appropriate airflow for the space. The evaporative cooling air process works by evaporating liquid water into vapor using heat from the air while the total energy in the air is maintained.</p>
                <p>The heat used to evaporate water into water vapor is called latent heat (hidden heat that cannot be detected with a thermometer) of evaporation. For example, it is the heat from the hot pavement that evaporates the water after a summer rainstorm. As the liquid water changes its state into vapor, it absorbs heat from its surroundings; the energy it absorbs is contained in the molecular structure of the vapors.</p>
                <p>Evaporative cooling is only possible because of this natural phenomenon of latent heat. This results in the temperature and the sensible heat (that you can feel or sense) of the air dropping. The moisture vapor that is added to the air increases the humidity and the latent heat of the air. An evaporative cooler is a system that cools air through the evaporation of water. Evaporative cooling is different from traditional air conditioning systems, which use chemical vapor compression refrigeration cycles.</p>
                <p>Evaporative cooling and evaporative coolers describe this natural phenomenon, however, there are many other terms that are commonly used with the same meaning including evaporative air conditioning, adiabatic cooling, ducted evaporative cooling, swamp coolers, and desert coolers.</p>

                <h1>EVAPORATIVE COOLING BENEFITS</h1>
                <p>The benefits of evaporative air conditioning are plenty, there’s never been a better time to invest in an evaporative air conditioner which is simple to maintain and operate and offers superior product performance.</p>
                <p>With electricity prices escalating and summers typically involving heat waves, evaporative air conditioning offers a significant and increasing edge on refrigerated cooling, when it comes to energy efficiency, healthier air flow, performance and lifestyle.</p>
        
                <ImageContainer>
                    <Image>
                        <img src="images/benefits/COST-EFFECTIVE.png" alt="" width="100vw" max-width="100vw" />
                        <span>Cost Effective</span>
                    </Image>
                    <Image>
                        <img src="images/benefits/LOW WATER USAGE.png" alt="" width="100vw" max-width="100vw" />
                        <span>Low Water Usage</span>
                    </Image>
                    <Image>
                        <img src="images/benefits/QUIET.png" alt="" width="100vw" max-width="100vw" />
                        <span>Quiet Operate</span>
                    </Image>
                    <Image>
                        <img src="images/benefits/EASY MAINTENANCE.png" alt="" width="100vw" max-width="100vw" />
                        <span>Easy Maintenance</span>
                    </Image>
                    <Image>
                        <img src="images/benefits/IMPROVED.png" alt="" width="100vw" max-width="100vw" />
                        <span>Improved Indoor Air Quality</span>
                    </Image>
                    <Image>
                        <img src="images/benefits/ENVIROMENTALLY FRIENDLY.png" alt="" width="100vw" max-width="100vw" />
                        <span>Enviromentally Friendly</span>
                    </Image>
                    <Image>
                        <img src="images/benefits/ADDING MOISTURE.png" alt="" width="100vw" max-width="100vw" />
                        <span>Adds Moisture To The Air</span>
                    </Image>
                </ImageContainer>
                <br />
                <h1>HOW DOES EVAPORATIVE AIR CONDITIONING WORK?</h1>
                <Image>
                <img src="images/Evaporative system.png" alt="" width="50%" max-width="100%" />
                </Image>
                
                <p>Ever wondered why you feel cooler by the ocean or a river? You feel cooler because as hot air blows across the water, it causes some of the water to evaporate and absorb the heat. The air is cooled by this evaporation process and so becomes a “sea” breeze</p>
                <p>A Braemar evaporative air conditioner works the same way. Hot air enters the cooling unit on your roof where it is filtered and cooled as it passes through specially designed moistened Chillcel® pads. A fan then blows this beautifully cooled air throughout your home.</p>
                <p>Hot air in the house is forced out through open windows and doors providing your entire home with a complete change of air at least every two minutes</p>
        
            </Container>  
        </>
    )
}

export default EvaporativeCoolers
