import React from 'react'
import { Content, ImageContainer, Image} from './PricingElement'

function Pricing() {
    return (
        <>
            <Content>
                <h1>Pricing for New units</h1>

                <p> Please click here to download<a href="Documents/Cooler Pricing 6 September 2021.pdf" download> Cooler Prices</a></p>
                
                <ImageContainer>
                    <Image>
                        <img src="../images/coolers/Breezair Icon Series Evaporative Coolers.png" alt="" width="300vw" />
                        <span>Icon Series</span>
                    </Image>
                    <Image>
                        <img src="../images/coolers/Braemar LPQI Series Evaporative Coolers.png" alt="" width="300vw" />
                        <span>LPQI Series</span>
                    </Image>
                    <Image>
                        <img src="../images/coolers/Breezair TBQI Series Evaporative Coolers.png" alt="" width="300vw" />
                        <span>TBQI Series</span>
                    </Image>
                    </ImageContainer>
                    <ImageContainer>
                    <Image>
                        <img src="../images/coolers/Breezair TBQ Series Evaporative Coolers.png" alt="" width="300vw"/>
                        <span>TBQ Series</span>
                    </Image>
                    <Image>
                        <img src="../images/coolers/Convair CVQ Series Evaporative Coolers.png" alt="" width="300vw" />
                        <span>CVQ Series</span>
                    </Image>
                    <Image>
                        <img src="../images/coolers/Braemar Ducted Gas Heaters.png" alt="" width="300vw" />
                        <span>Braemar Ducted </span>
                        <span> Gas Heaters</span>
                    </Image>
                </ImageContainer>
            </Content>
        </>
    )
}

export default Pricing
